.protocol {
  flex: 1 1 1px;
  padding: 5px 8px;
  color: var(--dark);
  text-decoration: none;
  margin-left: 5px;
  text-align: left;
  z-index: 5000;
}

.scroll-box {
    overflow-y: scroll;
    height: 100%;
    text-align: left;
    white-space: pre-line;
}

.protocol__body {
    --x: 0;
    --y: 0;
    --z: 0;
    display: block;
    width: 90vw;
    height: 86vh;
    background: var(--light);
    padding-bottom: 15px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0px -9px 50px -30px black;
    margin-left: 5vw;
    margin-right: 5vw;
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    bottom: 0;
    z-index: 105;
    transform: translate3d(var(--x), var(--y), var(--z));
    transition: 0.2s cubic-bezier(0.33, 1, 0.53, 1);
  }
  .protocol__body[hidden] {
    --y: 150%;
  }
  .protocol__body > *:not(:last-child) {
    border-bottom: 2px solid var(--neutral);
  }

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }