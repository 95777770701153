@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:700');
:root {
  --dark: #333;
  --light: #fff;
  --neutral: #f6f6f6;
  --neutral-dark: #d1d1d1;
  --color: #25509d;
  --color-light: #941ed2;
  --color-dark: #2719cd;
  --font-stack: 'Josefin Sans', 'Montserrat', 'sans-serif';
}

.hero__phone {
  width: 500;
  height: 100vh;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.hero__phone:before {
  content: '';
  width: 175px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translatex(-50%);
  z-index: 1000;
}
.hero__phone:after {
  content: '';
  width: 100px;
  height: 5px;
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translatex(-50%);
  z-index: 1000;
}

.menu__button {
  --x: -50%;
  --y: 0;
  --z: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--color);
  padding: 10px 20px;
  border-radius: 28px;
  box-shadow: 0 2px 40px -10px var(--color);
  color: var(--light);
  font-size: 16px;
  white-space: nowrap;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate3d(var(--x), var(--y), var(--z));
  cursor: pointer;
  z-index: 105;
  transition: 0.15s cubic-bezier(0.33, 1, 0.53, 1);
}
.menu__button[hidden] {
  --y: 200%;
}
.menu__button div {
  display: flex;
  align-items: center;
  width: 12px;
  height: 12px;
  margin-right: 20px;
}
.menu__button div div {
  display: table;
  height: 1px;
  background: var(--light);
  box-shadow: 0 4px 0 var(--light), 0 -4px 0 var(--light);
}

.menu__overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 100;
}

.menu__body {
  --x: 0;
  --y: 0;
  --z: 0;
  display: block;
  width: 100%;
  background: var(--light);
  padding-bottom: 15px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px -9px 50px -30px black;
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  bottom: 0;
  z-index: 105;
  transform: translate3d(var(--x), var(--y), var(--z));
  transition: 0.2s cubic-bezier(0.33, 1, 0.53, 1);
}
.menu__body[hidden] {
  --y: 150%;
}
.menu__header {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
