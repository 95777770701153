.disabled g:hover {
  fill: #9f9f9f;
}

.disabled g path:hover {
  fill: #9f9f9f;
}

.disabled path:hover {
  fill: #9f9f9f;
}

svg {
  fill: #9f9f9f;
}

.sector:hover {
  fill: #78bf00;
}
